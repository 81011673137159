<template>
	<div>
		<!-- Labeled Input -->
		<div
			v-if="showLabel"
			:class="showLabel ? 'm-0' : ''"
			class="form-group">
			<label
				v-if="label.length"
				:for="id"
				class="label">
				{{ label }}
				<span
					v-if="required"
					class="text-danger">*</span>
				<slot name="afterLabelInline" />
			</label>
			<b-form-select
				:id="id"
				v-model="inputValue"
				:name="id"
				class="form-control"
				:class="hasErrors ? 'is-invalid' : ''"
				:disabled="disabled"
				:options="options">
				<template
					v-if="firstOption"
					#first>
					<b-form-select-option
						value=""
						disabled>
						{{ firstOption }}
					</b-form-select-option>
				</template>
			</b-form-select>
			<!-- Errors Alert -->
			<template v-if="hasErrors">
				<span
					v-for="error in errors"
					:key="error"
					class="invalid-feedback animated fadeIn"
					v-text="error" />
			</template>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Select',
	props: {
		id: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		setterValue: {
			type: [String, Number],
			default: '',
		},
		errors: {
			type: Array,
			default() {
				return [];
			},
		},
		required: {
			type: Boolean,
			default: false,
		},
		showLabel: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		options: {
			type: Array,
			default: () => [],
		},
		firstOption: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			inputValue: '',
		};
	},
	computed: {
		hasErrors() {
			return this.errors.length > 0;
		},
	},
	watch: {
		inputValue(newValue) {
			this.$emit('dataChanged', newValue);
		},
		setterValue(newVal) {
			this.inputValue = newVal;
		},
	},
	created() {
		this.inputValue = this.setterValue;
	},
};
</script>
<style lang="scss" scoped>
.is-invalid {
	.input-group-text {
		border-color: #f86c6b;
	}
}
</style>
