import { API_URL } from '@/settings/Captcha';

export default {
	data() {
		return {
			apiUrl: API_URL,
		};
	},
	mounted() {
		const recaptchaScript = document.createElement('script');
		recaptchaScript.setAttribute('src', this.apiUrl + process.env.VUE_APP_RECAPTCHA_SITE_KEY);
		recaptchaScript.setAttribute('id', 'google_captcha');
		document.body.appendChild(recaptchaScript);
	},
	beforeDestroy() {
		const recaptchaScript = document.getElementById('google_captcha');
		if (recaptchaScript) {
			recaptchaScript.parentNode.removeChild(recaptchaScript);
		}

		const recaptchaBadge = document.getElementsByClassName('grecaptcha-badge');
		if (recaptchaBadge) {
			for (let i = 0; i < recaptchaBadge.length; i += 1) {
				recaptchaBadge[i].parentNode.removeChild(recaptchaBadge[i]);
			}
		}
	},
	methods: {
		getCaptchaToken(action) {
			return new Promise((resolve, reject) => {
				try {
					if (typeof window.grecaptcha !== 'undefined') {
						window.grecaptcha.ready(() => {
							window.grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action }).then((token) => {
								resolve(token);
							}, () => {
								resolve('recaptcha_error');
							});
						});
					} else {
						resolve('recaptcha_error');
					}
				} catch (err) {
					reject(err);
				}
			});
		},
	},
};
