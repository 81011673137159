export default {};
export const MAX_CHAR_NAMES = 50;
export const AVAILABLECC = ['american_express', 'amex', 'diners', 'discover', 'jcb', 'mastercard', 'visa', 'unionpay'];
export const ALLOWEDCC_BY_COUNTRY = {
	CN: ['diners_club', 'discover', 'mastercard', 'visa', 'american_express', 'unionpay'],
	default: ['diners_club', 'discover', 'mastercard', 'visa', 'american_express'],
};

export const VGS_V2_SCRIPT_SOURCE = 'https://js.verygoodvault.com/vgs-collect/2.12.0/vgs-collect.js';
export const CREDIT_CARD_FORM_METHODS = ['purchaseorder', 'creditcard', 'purchaseorderintl', 'lptunionpay'];
