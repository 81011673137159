// Countries where Phone Verification applies
export const VERIFY_PHONE_COUNTRIES = [
	'US', 'CA', 'MX', 'HU', 'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT', 'LV',
	'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'GB', 'SK', 'SI', 'ES', 'SE', 'PH', 'AR', 'AU', 'BD', 'BR', 'CL', 'CO',
	'CR', 'CD', 'EC', 'GH', 'GI', 'HN', 'HK', 'IS', 'IN', 'ID', 'KE', 'LI', 'MQ', 'MU', 'MC', 'MA', 'NZ', 'NO',
	'PA', 'PE', 'RS', 'SG', 'ZA', 'CH', 'TW', 'TR', 'UG', 'UY', 'ZM', 'NG', 'MY', 'DO', 'PY', 'PG', 'GT', 'SV', 'CN',
];

// Countdown before being able to resend verification code SMS
export const RESEND_CODE_COOLDOWN = 15; // 15 seconds

// Phone verification states
const VERIFICATION_REQUIRED = 'REQUIRED';
const VERIFICATION_NOT_REQUIRED = 'NOT_REQUIRED';

export const PHONE_VERIFICATION_STATES = {
	VERIFICATION_REQUIRED,
	VERIFICATION_NOT_REQUIRED,
};

// Phone verification steps
const VERIFY_PHONE_NUMBER = 'VERIFY_PHONE_NUMBER';
const VERIFY_PHONE_CODE = 'VERIFY_PHONE_CODE';

export const PHONE_VERIFICATION_STEPS = {
	VERIFY_PHONE_NUMBER,
	VERIFY_PHONE_CODE,
};

// Phone verification response statuses
const SENT = 'sent';
const WHITELISTED = 'whitelisted';

export const PHONE_VERIFICATION_RESPONSE_STATUSES = {
	SENT,
	WHITELISTED,
};

export default {};
