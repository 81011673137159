import {
	REGISTER_VALIDATOR,
	BECOME_DISTRIBUTOR_VALIDATOR,
	PREREGISTER_VALIDATOR,
	CHECKOUT_VALIDATOR,
	RENEWAL_VALIDATOR,
	USER_INFO,
	PURCHASE_INFORMATION,
	UPDATE_PURCHASE_INFORMATION,
	PURCHASE,
	RETRY_EXTERNAL_PAYMENT,
	RETRY_PAYMENT,
	CREATE_AFFILIATE,
	PACK_PURCHASE,
	TRANSFER_FEE_VALIDATOR,
	UPDATE_GIFT_ORDER_CART_INFO,
	CREATE_GIFT_ORDER,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class Purchase {
	constructor() {
		this.data = new Req();
		this.customDataObject = {};
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getSponsorInfo(user) {
		const { method, endpoint } = USER_INFO;
		return this.data[method](endpoint(user)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPurchaseInformation() {
		const { method, endpoint } = PURCHASE_INFORMATION;
		return this.data[method](endpoint).then((response) => response).catch((error) => Promise.reject(error));
	}

	updatePurchaseInformation(payload) {
		const { method, endpoint } = UPDATE_PURCHASE_INFORMATION;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
	}

	updateGiftOrderInformation(payload) {
		const { method, endpoint } = UPDATE_GIFT_ORDER_CART_INFO;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
	}

	confirmPurchase(payload) {
		const { method, endpoint } = PURCHASE;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
	}

	confirmTransferFee(payload, userId) {
		const { method, endpoint } = TRANSFER_FEE_VALIDATOR;
		return this.data[method](endpoint(userId), payload).then((response) => response).catch((error) => Promise.reject(error));
	}

	retryExternalPayment(orderId) {
		const { method, endpoint } = RETRY_EXTERNAL_PAYMENT;
		return this.data[method](endpoint(orderId));
	}

	retryPayment(options) {
		const { method, endpoint } = RETRY_PAYMENT;
		const queryParams = Req.setFilters(options);
		return this.data[method](endpoint(queryParams));
	}

	validate(payload, currentModule, userId) {
		if (typeof payload === 'undefined'
			|| (payload instanceof FormData && !payload.has('step'))
			|| (!(payload instanceof FormData) && payload.step === undefined)) {
			return Promise.reject(new Error('invalid_step'));
		}

		if (currentModule === 'RegisterOld' || currentModule === 'Register') {
			const { method, endpoint } = REGISTER_VALIDATOR;

			this.options.data = payload;
			this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
			this.options.url = endpoint;
			this.options.method = method;

			return this.data.postFormData(this.options).then((response) => response).catch((error) => Promise.reject(error));
		}

		if (currentModule === 'Purchase') {
			const { method, endpoint } = PACK_PURCHASE;
			return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
		}

		if (currentModule === 'GiftOrder') {
			const { method, endpoint } = CREATE_GIFT_ORDER;
			return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
		}

		if (currentModule === 'Checkout') {
			const { method, endpoint } = CHECKOUT_VALIDATOR;

			this.options.data = payload;
			this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
			this.options.url = endpoint;
			this.options.method = method;

			return this.data.postFormData(this.options).then((response) => response).catch((error) => Promise.reject(error));
		}

		if (currentModule === 'AgencyDirectCheckout' || currentModule === 'AgencyNootropicsCheckout') {
			const { method, endpoint } = CHECKOUT_VALIDATOR;
			return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
		}

		if (currentModule === 'BecomeDistributor') {
			const { method, endpoint } = BECOME_DISTRIBUTOR_VALIDATOR;
			return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
		}

		if ((currentModule === 'Renewal') && typeof userId !== 'undefined') {
			const { method, endpoint } = RENEWAL_VALIDATOR;
			return this.data[method](endpoint(userId), payload).then((response) => response).catch((error) => Promise.reject(error));
		}

		if ((currentModule === 'TransferFee') && typeof userId !== 'undefined') {
			const { method, endpoint } = TRANSFER_FEE_VALIDATOR;
			return this.data[method](endpoint(userId), payload).then((response) => response).catch((error) => Promise.reject(error));
		}

		if (currentModule === 'Preregister' || currentModule === 'SoftRegister') {
			const { method, endpoint } = PREREGISTER_VALIDATOR;
			return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
		}

		if (currentModule === 'BecomeAffiliateMain') {
			const { method, endpoint } = CREATE_AFFILIATE;
			return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
		}

		return Promise.reject(new Error('module_not_found'));
	}
}

export default Purchase;
